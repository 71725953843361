<script>
import Switcher from "@/components/switcher";
import { VueTyper } from "vue-typer";
import { HomeIcon } from "vue-feather-icons";

/**
 * Page-cominsoon-2 component
 */
export default {
  data() {
    return {
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      starttime: "Thu Mar 17 2022 14:57:26",
      endtime: "Thu May 30 2022 14:57:26",
    };
  },
  components: {
    Switcher,
    "vue-typer": VueTyper,
    HomeIcon,
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    },
  },
};
</script>

<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-soft-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- COMING SOON PAGE -->
    <section
      class="bg-home bg-animation-left dark-left d-flex align-items-center"
      data-jarallax='{"speed": 0.5}'
      style="background-image: url('images/equipos-tratamiento.jpg')"
      id="home"
    >
      <video autoplay muted loop id="myVideo">
        <source
          src="https://fibrasynormasdecolombia15.s3.sa-east-1.amazonaws.com/videorender.mp4"
          type="video/mp4"
        />
      </video>
      <div
        class="container position-relative text-md-left text-center"
        style="z-index: 1"
      >
        <div class="row">
          <div class="col-md-12">
            <a href="javascript:void(0)" class="logo h5"
              ><img src="images/logo.png" height="110" alt="logo"
            /></a>
            <h1
              class="text-uppercase text-white title-dark mt-2 mb-4 coming-soon"
            >
              <vue-typer
                class="text-white"
                :text="[
                  'Esperanos muy pronto...',
                  'Con equipos para',
                  'Tratamiento de agua',
                ]"
              ></vue-typer>
            </h1>
            <p class="text-light para-dark para-desc">
              Estamos innovando para el mercado de
              <span class="font-weight-bold"
                >equipos de tratamiento de agua</span
              >
              para brindarle a nuestros clientes una experiencia mejorada en
              calculos y diseños de nuestros equipos.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div id="countdown">
              <div class="count-down">
                <span class="count-number">{{ days }}</span>
                <span class="count-head position-relative d-bock">Dias</span>
              </div>
              <div class="count-down">
                <span class="count-number">{{ hours }}</span>
                <span class="count-head position-relative d-bock">Horas</span>
              </div>
              <div class="count-down">
                <span class="count-number">{{ minutes }}</span>
                <span class="count-head position-relative d-bock">Minutos</span>
              </div>
              <div class="count-down">
                <span class="count-number">{{ seconds }}</span>
                <span class="count-head position-relative d-bock"
                  >Segundos</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <a
              href="https://www.fibrasynormasdecolombia.com/"
              class="btn btn-primary mt-4 mr-2"
              ><i class="mdi mdi-backup-restore"></i> Ir a sitio antiguo</a
            >
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
    <!-- COMING SOON PAGE -->
    <Switcher />
  </div>
</template>

<style>
.vue-typer .custom.char.typed {
  color: white;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 70%;
  background-color: rgba(0, 0, 0, 0.568);
}
</style>
